import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import Prism from "prismjs";
import "./menu.scss";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { UpdateMain } from "../../../application/actions/menuAction";
import { Imgaeupload } from "../../../application/actions/menuAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import ManageMainMenu from "./manage-menu";
import environment from "../../../infrastructure/core/Environment";
import { restrictAllNumbers, restrictAllSpecialCharacters, restrictConsecutiveSpace, restrictOnlyNumber, validateRequired, validateRequiredDropdown } from "../../../infrastructure/core/validationUtils";
import emptyImg from "../../../assets/img/empty-image/placeholde-image.svg";

export default function EditMainMenu(props) {
  const Status = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "STS"
  );
  const { user } = useContext(AuthContext);
  const initialState = {
    id: "",
    mainCatName: "",
    mainCatDesc: "",
    isVisible: true,
    sequence: "",
    isDeleted: false,
    subMenuCount: 0,
    createdBy: user.id,
    modifiedBy: user.id,
    status: `${environment.defaultValue}`,
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.data) {
      setFormData({
        ...formData,
        id: props.data.id,
        mainCatName: props.data.mainCatName,
        mainCatDesc: props.data.mainCatDesc,
        isVisible: props.data.isVisible,
        sequence: props.data.sequence,
        isDeleted: false,
        status: props.data.status,
      });
    }
  }, [props.data]);

  const fileInputRef = useRef(null);
  const [imagePath, setImagePath] = useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imagePath = e.target.result;
        setImagePath(imagePath);
        const image = imagePath.split(",")[1];
        const id = props?.data?.id;
        const data = {
          image: file,
          id: id,
          foldername: "main-Category-Images",
        };
        dispatch(Imgaeupload(data));
      };

      reader.readAsDataURL(file);
    }
  };
  const [errors, setErrors] = useState({});
  const validateMainCatName = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.mainCatName = validateRequired(formData.mainCatName)
      ? ""
      : "Main Menu is required";
    // Validate mainCatId (dropdown)
    newErrors.status = validateRequiredDropdown(formData.status)
      ? ""
      : "Please Select Visibility";

      setErrors(newErrors);
      // Check if any field has errors
      return !Object.values(newErrors).some((error) => error);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // Validate mainCatName before submitting
    const isValid = validateMainCatName();
    if (!isValid) {
      return;
    }
    dispatch(UpdateMain(formData));
    setFormData(initialState);
    props.closeFunction();
    props?.handleManage();
  };

  return (
    <React.Fragment>
      {/* <ManageMainMenu  show={showManageMenu} closeFunction={() => setShowManageMenu(false)}/> */}
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title>Edit Main Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Edit Menu Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Item Name"
                  name="mainCatName"
                  value={formData.mainCatName}
                  onChange={(e) =>{
                      setFormData({ ...formData, mainCatName: e.target.value })
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      mainCatName:  "" ,
                    }));}
                  }
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                    restrictAllNumbers(e);
                  }}                />
                <Form.Text className="text-danger">
                  {errors.mainCatName}
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Short Description"
                  name="mainCatDesc"
                  value={formData.mainCatDesc}
                  onChange={(e) =>
                    setFormData({ ...formData, mainCatDesc: e.target.value })
                  }
                  onKeyDown={restrictConsecutiveSpace}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Main Menu Status: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  // defaultValue={defaultValue}
                  value={formData.status}
                  onChange={(e) => {
                    setFormData({ ...formData, status: e.target.value });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      status:  "" ,
                    }));
                  }}
                >
                  <option value={`${environment.defaultValue}`} disabled>
                    Choose Menu Status
                  </option>
                  {Status?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.status}
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <div className="mb-4">
                <Form.Label>Menu Sequence</Form.Label>
                <Form.Control
                  type="text"
                  name="sequence"
                  value={formData.sequence}
                  onChange={(e) =>
                    setFormData({ ...formData, sequence: e.target.value })
                  }
                  onKeyDown={restrictOnlyNumber}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Menu Icon</Form.Label>
                  <div className="custom-update-image">
                    <Form.Control
                      type="file"
                      className="custom-file-input"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept=".jpg, .jpeg, .png"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileInput"
                    >
                      <img
                        src={`https://fudeasy.oss-me-east-1.aliyuncs.com/FE/main-Category-Images/${props?.data?.id}/${props?.data?.id}.png?version=${Math.floor(Math.random() * 1000000)}}`}
                        onError={(e) => {e.target.src = emptyImg;}}
                        className="img-fluid"
                        alt="thumbnail"
                      />
                      {/* <div>
                            <i className="ri-upload-line"></i> <span className="d-block">Browse</span>
                        </div> */}
                    </label>
                    {/* <Button variant="primary" onClick={handleButtonClick}></Button> */}
                  </div>
                </Form.Group>
              </div>
            </Col>
          </Row>

          {/* <hr /> */}

          {/* <div>
                <div className="mb-2 d-flex align-items-center justify-content-between w-100">
                    <div>
                        <h4 className="main-title fs-14 mb-0">Translation</h4>
                    </div>
                    <div>
                        <Button variant="primary" className="fs-14 d-flex align-items-center"> <i className="ri-add-line fs-18 lh-1 align-middle"></i><span className="align-middle">ADD NEW</span></Button>
                    </div>
                </div>
                <Table className="table mb-0" responsive>
                <thead>
                    <tr>
                        <th>Main Menu Name</th>
                        <th>Description</th>
                        <th>Language</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>शोरबा और सलाद</td>
                        <td>यहाँ मेनू एक नामुना विवरन है</td>
                        <td>हिंदी</td>
                        <td className="w-0">
                            <div className="d-flex align-items-center">
                                <Button variant="primary" className="btn-icon me-2"><i className="ri-pencil-line"></i></Button>
                                <Button variant="danger" className="btn-icon"><i className="ri-delete-bin-line"></i></Button>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                </Table>
            </div> */}
        </Offcanvas.Body>
        <div className="offcanvas-footer" onClick={onSubmit}>
          <Button variant="primary" className="fs-14 d-felx align-items-center">
            {" "}
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Save Changes</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
//prachi

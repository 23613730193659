import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Col, Offcanvas, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { AddVariance } from "./../../../../application/actions/varianceAction";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "./../../../../infrastructure/core/helpers/AuthContext";
import { getCommonFieldTypeMSMT } from "../../../../application/selectors/indexSelector";
import Environment from "../../../../infrastructure/core/Environment";
import { useParams } from "react-router-dom";

export default function AddNewVariantModal(props) {
  const { id } = useParams();

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const initialState = {
    id: undefined,
    isVisible: true,
    isDeleted: false,
    createdBy: user.id,
    modifiedBy: user.id,
    status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
    catalogueId: id,
    variantMode: Environment.defaultValue,
    variantType: "",
    variantName: "",
    sizeId: "00000000-0000-0000-0000-000000000000",
    sellingPrice: "",
    markupPrice: "",
    preparationPrice: "",
    locations: [],
    measurmentTypeId: Environment.defaultValue,
    IsGlobal: props?.IsGlobal,
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    setFormData({
      ...formData,
      catalogueId: id,
      IsGlobal: props?.IsGlobal,
    });
  }, [id]);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedMeasurement, setSelectedMeasurement] = useState([]);
  const MeasurementTypeList = useSelector(getCommonFieldTypeMSMT);
  let MeasurementList = [];

  const handleSizeSelect = (event) => {
    const selectedValue = event.target.value;
    const selectedData = props?.SizeList?.filter(
      (item) => item.id === event.target.value
    );
    setFormData({ ...formData, variantType: selectedValue });
    setSelectedSize(selectedData[0]);
  };
  const handleMeasurementSelect = (event) => {
    const selectedValue = event.target.value;
    setFormData({
      ...formData,
      measurmentTypeId: selectedValue,
      IsGlobal: props?.IsGlobal,
      catalogueId: id,
    });
    const selectedData = MeasurementTypeList?.find(
      (item) => item.id === event.target.value
    );
    MeasurementList = JSON.parse(localStorage.getItem("CommonFields")).filter(
      (item) => item.fieldTypeCode === selectedData.shortCode
    );
    setSelectedMeasurement(MeasurementList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(AddVariance(formData));
    handleClose()
  };
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleLocation = (location) => {
    setSelectedOptions(location);
    const locationdata = location.map((loc) => loc.value);
    setFormData({ ...formData, locations: locationdata, IsGlobal: false });
  };
  const BranchList = props?.BranchData?.map((branch) => ({
    value: branch.id,
    label: branch.branchName,
  }));
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(true); // Toggle dropdown menu open/close state
  };

  const closeDropdown = () => {
    setIsOpen(false); // Close dropdown menu
  };
  const handleClose =()=>{
    setFormData(initialState);
    setSelectedSize("");
    setSelectedMeasurement([]);
    setSelectedOptions([]);
    props.closeFunction();  
  }
  console.log(props.hasVariant);
  
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16">
            {props?.variantData?.id !== undefined
              ? "Update Variant"
              : "Add New Variant"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {props?.hasVariant &&(<>
            <Row>
            <Col xl={8}>
              <div className="mb-3">
                <Form.Label>Select Variant Type</Form.Label>
                <Form.Select
                  name="variantType"
                  value={formData.variantType}
                  onChange={handleSizeSelect}
                >
                  <option value="Choose Variant Type">
                    Choose Variant Type
                  </option>
                  {props?.SizeList?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          {/* Measurement */}
          {selectedSize?.id === "d9c5a952-911e-4a0f-8935-5e1fae01902e" && (
            <Row>
              <Col xl={8}>
                <div className="mb-3">
                  <Form.Label>Select Measurement</Form.Label>
                  <Form.Select
                    name="measurmentTypeId"
                    value={formData.measurmentTypeId}
                    onChange={handleMeasurementSelect}
                  >
                    <option value="Choose Variant Type">
                      Choose Variant Type
                    </option>
                    {MeasurementTypeList?.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
            </Row>
          )}

          {/* Size */}
          {selectedSize?.id === "d9c5a952-911e-4a0f-8935-5e1fae01902e" && (
            <Row>
              <Col xl={8}>
                <div className="mb-3">
                  <Form.Label>
                    Choose Size <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Select
                    name="sizeId"
                    value={formData.sizeId}
                    onChange={(e) =>
                      setFormData({ ...formData, sizeId: e.target.value })
                    }
                  >
                    <option>Choose Size</option>
                    {selectedMeasurement?.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.fieldValue}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
            </Row>
          )}

          {/* Variant Name */}
          {(selectedSize?.id === "d9c5a952-911e-4a0f-8935-5e1fae01901e" ||
            selectedSize?.id === "d9c5a952-911e-4a0f-8935-5e1fae01902e") && (
            <Row>
              <Col xl={8}>
                <div className="mb-3">
                  <Form.Label>
                    Variant Name: <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Variant Name"
                    name="variantName"
                    value={formData.variantName}
                    onChange={(e) =>
                      setFormData({ ...formData, variantName: e.target.value })
                    }
                  />
                </div>
              </Col>
            </Row>
          )}
          </>)}
        

          {/* Selling Price, Markup Price, Preparation Cost */}
          {(selectedSize?.id === "d9c5a952-911e-4a0f-8935-5e1fae01901e" ||
            selectedSize?.id === "d9c5a952-911e-4a0f-8935-5e1fae01902e" ||
            selectedSize?.id === "d9c5a952-911e-4a0f-8935-5e1fae01903e" || 
            !props?.hasVariant )&& (
            <>
              <Row>
                <Col xl={8}>
                  <div className="mb-3">
                    <Form.Label>
                      Selling Price: <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="1"
                      placeholder="Enter Selling Price"
                      name="sellingPrice"
                      value={formData.sellingPrice}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          sellingPrice: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={8}>
                  <div className="mb-3">
                    <Form.Label>
                      Markup Price: <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="1"
                      placeholder="Enter Markup Price"
                      name="markupPrice"
                      value={formData.markupPrice}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          markupPrice: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={8}>
                  <div className="mb-3">
                    <Form.Label>
                      Preparation Cost: <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="1"
                      placeholder="Enter Preparation Cost"
                      name="preparationPrice"
                      value={formData.preparationPrice}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          preparationPrice: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              {props?.variantData?.id === undefined && !props?.IsGlobal && (
                <Row>
                  <Col xl={8}>
                    <div className="mb-3">
                      <Form.Label>
                        Location: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <div onBlur={closeDropdown}>
                        <div onClick={toggleDropdown}>
                          <Select
                            options={BranchList}
                            isSearchable={true}
                            isMulti
                            value={selectedOptions}
                            onChange={handleLocation}
                            menuIsOpen={isOpen}
                            onMenuClose={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Offcanvas.Body>
        <div className="offcanvas-footer bg-white">
          <Button
            variant="primary"
            className="fs-14 d-flex align-items-center"
            type="submit"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-16 lh-1 align-middle me-1"></i>
            <span className="align-middle">
              {props?.variantData?.id !== undefined
                ? "Update Variant"
                : "Create New Variant"}
            </span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}

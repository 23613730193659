// export class Environment {
const live = "https://uatgateway.fudeasy.com/api/";
const local = "https://localhost:7049/api/";
const stage = "";
const defaultValue = "00000000-0000-0000-0000-000000000000";
const baseUrl = stage === "dev" ? local : live;
const DashBoard_Url = `${baseUrl}Dashboard/`;
const Product_Url = `${baseUrl}Product`;
const Orders_Url = `${baseUrl}Orders`;
const Account_Url = `${baseUrl}Account/`;
const Attendance_Url = `${baseUrl}Attendance/`;
const Catalogue_Url = `${baseUrl}Catalogue/`;
const Category_Url = `${baseUrl}Category/`;
const Setting_Url = `${baseUrl}Setting/`;
const SetUp_Url = `${baseUrl}Setup/`;
const User_Url = `${baseUrl}Users/`;
const Sales_Url = `${baseUrl}Sale/`;
const MSP_Url = `${baseUrl}DashboardMSP/`;
const Sync_Url = `${baseUrl}Sync/`;
const Vendor_Url = `${baseUrl}Vendor/`;

export default {
  DashBoard_Url,
  Product_Url,
  Orders_Url,
  Account_Url,
  Attendance_Url,
  Catalogue_Url,
  Category_Url,
  Setting_Url,
  SetUp_Url,
  User_Url,
  Sales_Url,
  MSP_Url,
  Sync_Url,
  defaultValue,
  Vendor_Url
};

import React, { useContext, useEffect, useRef, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getProfileByTenant,
} from "../../../application/selectors/indexSelector";
import { Link } from "react-router-dom";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { ImageUpload, UpdateBusinessProfile } from "../../../application/actions/accountAction";
import Environment from "../../../infrastructure/core/Environment";

export default function EditDetails() {
 // Redux
 const dispatch = useDispatch();
 const profileByTenant = useSelector(getProfileByTenant);
 const { loading } = useSelector(getLoading);

 // Context
 const { user } = useContext(AuthContext);

 // State
 const [imagePath, setImagePath] = useState(null);
 const [formData, setFormData] = useState({});
 const [fudEasyManaged, setFudEasyManaged] = useState(true);

 // Common fields
  const domainCommonList = JSON.parse(localStorage.getItem("CommonFields"))?.filter(
    (fields) => fields.fieldTypeCode === "DTYP"
  ) || [];

    // Load profile data and set form data
  useEffect(() => {
    const FudEasySubDomain = profileByTenant?.subDomainName?.split('.');
    setFormData({
      ...formData,
      id: profileByTenant.id,
      tenantId: profileByTenant.tenantId,
      branchId: profileByTenant.branchId,
      status: profileByTenant.status,
      isVisible: profileByTenant.isVisible,
      isDeleted: profileByTenant.isDeleted,
      modifiedBy: user.id,
      businessName: profileByTenant.businessName,
      domainType: profileByTenant.domainType,
      fudEasydomain: profileByTenant.domainType === "d9c5a952-911e-4a0f-8935-5e1fae02901e" ? FudEasySubDomain[0] : "",
      customerDomainName: profileByTenant.domainType === "d9c5a952-911e-4a0f-8935-5e1fae02902e" ? profileByTenant.subDomainName : "", 
    });
    const domainTypeBool = profileByTenant?.domainType === "d9c5a952-911e-4a0f-8935-5e1fae02902e" ? false : true;
    setFudEasyManaged(domainTypeBool);
  }, [profileByTenant]);

   // Highlight code blocks
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  // File input reference
  const fileInputRef = useRef(null);

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePath(e?.target?.result);
        let imagePathUrl = e?.target?.result;
        const data = {
          id:user?.tenanatId,
          foldername: 'userProfile',
          image: file,
        };
        dispatch(ImageUpload(data));
      };
      reader.readAsDataURL(file);
    }
  };
  // Handle  change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "domainType") {
      const domainTypeBool = value === "d9c5a952-911e-4a0f-8935-5e1fae02902e" ? false : true;
      setFudEasyManaged(domainTypeBool);
    }
  }
    // Handle form submission

    const handleSubmit = () => {
      const formObject = {
        id: formData.id,
        tenantId: formData.tenantId,
        branchId: formData.branchId,
        status: formData.status,
        isVisible: formData.isVisible,
        isDeleted: formData.isDeleted,
        modifiedBy: formData.id,
        businessName: formData.businessName,
        domainType: formData.domainType,
        subDomainName: formData.domainType === "d9c5a952-911e-4a0f-8935-5e1fae02901e" ? formData.fudEasydomain + ".fudeasy.com" : formData.customerDomainName,
      };
      dispatch(UpdateBusinessProfile(formObject))
    };
    return (
      <React.Fragment>
        <div>
       
          <div className="item-manage-thumbnail">
            <div className="text-center bg-white thumbnail mb-2 me-0 ms-0">
              <Form.Group controlId="formFile">
                <div className="custom-update-image">
                  <Link className="edit-image bg-dark text-white">
                    <i className="ri-pencil-line fs-16"></i>
                  </Link>
                  <div className={`${loading ? "placeholder" : ""} uploaded-image`}>
                    <Form.Control type="file" className="custom-file-input"
                      ref={fileInputRef} onChange={(e) => handleFileChange(e)} accept=".jpg, .jpeg, .png" />
                    <img src={`https://fudeasy.oss-me-east-1.aliyuncs.com/FE/userProfile/${user?.tenanatId}/${user?.tenanatId}.png`}
                      // onError={(e) => {
                      //   e.target.src = "https://fudeasy.oss-me-east-1.aliyuncs.com/FE/catalogueThumbnail/noImage.png";
                      // }}
                      className={`${loading ? "placeholder" : ""} img-fluid w-100 ht-100`} alt="thumbnail" srcSet={imagePath} />
                  </div>
                </div>
              </Form.Group>
            </div>
            <h3 className="fs-14 text-dark">Business Logo</h3>
            <p className="fs-sm text-secondary mb-0">
             Only *.png *.jpg *.jpeg image file
              are accepted
            </p>
          </div>
        </div>
        <hr />
        <Row>
          <Col xl="4">
            <div className="mb-4">
              <Form.Label>Business Name: <sup className="text-danger">*</sup></Form.Label>
              <Form.Control type="text" name="businessName" value={formData.businessName} onChange={handleChange} placeholder="Enter Business Name" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl="5">
            <div className="mb-4">
              <Form.Label>
                Domain Type: <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Select aria-label="Default select example" name="domainType" value={formData.domainType}
                onChange={handleChange} >
                <option value={Environment.defaultValue} disabled>Choose Domain Type</option>
                {domainCommonList.map((type,index) => (
                  <option key={index} value={type.id}>
                    {type.fieldValue}
                  </option>
                ))}
             </Form.Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl="5">
            {fudEasyManaged ? (
              <>
                <div className="mb-4">
                  <Form.Label>
                    FudEasy Domain Name: <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Group className="position-relative overflow-hidden">
                    <Form.Control className="tax-percentage-input" style={{ paddingRight: "50px" }}
                      type="text" placeholder="Enter FudEasy Domain Name"
                    value={ formData.fudEasydomain}  name="fudEasydomain" onChange={handleChange} />
                    <Form.Label className="custom-label mb-0 end-0 pe-2 ps-2" style={{ left: "auto", width: "auto" }} >.fudeasy.com</Form.Label>
                  </Form.Group>
                </div>
              </>
            ) : (
              <>
                {/* Condition Base */}
                <div className="mb-4">
                  <Form.Label>Custom Domain Name: <sup className="text-danger">*</sup></Form.Label>
                  <Form.Control type="text" name="customerDomainName" value={formData.customerDomainName} onChange={handleChange} placeholder="Enter Custom Domain Name" />
                </div>
              </>
            )}
          </Col>
        </Row>

        <div>
          <Button variant="primary" className="fs-14 d-flex align-items-center" type="submit" onClick={handleSubmit}>
            <i className="ri-pencil-line me-1"></i>
            <span className="align-middle">Change</span>
          </Button>
        </div>
      </React.Fragment>
    );
  }

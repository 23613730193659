import React, { useEffect, useState, useRef } from "react";
import Prism from "prismjs";
import { getLoading } from "../../../application/selectors/indexSelector";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import Environment from "./../../../infrastructure/core/Environment";
import { Link } from "react-router-dom";
import { Imgaeupload } from "../../../application/actions/ItemAction";
import PlaceholderImage from "../../../assets/img/empty-image/placeholde-image.svg";

export default function KOTSlip(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);


  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { loading } = useSelector(getLoading);

  const [imagePath, setImagePath] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imagePath = e.target.result;
        setImagePath(imagePath);
        const image = imagePath.split(",")[1];
        const id = 2;
        const data = {
          image: image,
          id: id,
          foldername: "Icon",
        };
        dispatch(Imgaeupload(data));
      };

      reader.readAsDataURL(file);
    }
  };

  const initialState = {
    branchId: Environment.defaultvalue,
    status: Environment.defaultvalue,
    isVisible: false,
    isDeleted: false,
    templateName: "",
    templateTypeId: Environment.defaultvalue,
    isLogo: false,
    isOrderType: false,
    isOrderTypeBold: false,
    isOrderTypeItalic: false,
    isOrderTypeUnderlined: false,
    isOrderTypeFontSize: false,
    isKOTId: false,
    isKOTIdBold: false,
    isKOTIdItalic: false,
    isKOTIdUnderlined: false,
    isKOTIdFontSize: false,
    isKitchenName: false,
    isKitchenNameBold: false,
    isKitchenNameItalic: false,
    isKitchenNameUnderlined: false,
    isKitchenNameFontSize: false,
    isSection: false,
    isSectionBold: false,
    isSectionItalic: false,
    isSectionUnderlined: false,
    isSectionFontSize: false,
    isTableNumber: false,
    isTableNumberBold: false,
    isTableNumberItalic: false,
    isTableNumberUnderlined: false,
    isTableNumberFontSize: false,
    isServedByName: false,
    isServedByNameBold: false,
    isServedByNameItalic: false,
    isServedByNameUnderlined: false,
    isServedByNameFontSize: false,
    isItemName: false,
    isItemNameBold: false,
    isItemNameItalic: false,
    isItemNameUnderlined: false,
    isItemNameFontSize: false,
    isItemCustomization: false,
    isItemCustomizationBold: false,
    isItemCustomizationItalic: false,
    isItemCustomizationUnderlined: false,
    isItemCustomizationFontSize: false,
    isItemRemark: false,
    isItemRemarkBold: false,
    isItemRemarkItalic: false,
    isItemRemarkUnderlined: false,
    isItemRemarkFontSize: false,
    isTitleText: false,
    isTitleTextBold: false,
    isTitleTextItalic: false,
    isTitleTextUnderlined: false,
    isTitleTextFontSize: false,
    isCancelledItems: false,
    isCacelledItemsBold: false,
    isCacelledItemsItalic: false,
    isCacelledItemsUnderlined: false,
    isCacelledItemsFontSize: false,
    isPrintedOn: false,
    isPrintedOnBold: false,
    isPrintedOnItalic: false,
    isPrintedOnUnderlined: false,
    isPrintedOnFontSize: false,
    isReprintedIndicator: false,
    isReprintedIndicatorBold: false,
    isReprintedIndicatorItalic: false,
    isReprintedIndicatorUnderlined: false,
    isReprintedIndicatorFontSize: false,
  };
  // cancelledItemName: false,

  const [formData, setFormData] = useState(initialState);

  return (
    <React.Fragment>
      {/* HEADER CONTENT */}
      <div>
        <div className="d-flex mt-3 mb-4">
          <div className="me-3">
            <div className="item-manage-thumbnail">
              <div className="text-center bg-white thumbnail h-100">
                <Form.Group className="h-100" controlId="formFile">
                  <div className="custom-update-image">
                    <Link className="edit-image bg-dark text-white">
                      <i className="ri-pencil-line fs-16"></i>
                    </Link>
                    <div className={`${loading ? "placeholder" : ""}uploaded-image`}>
                      <Form.Control
                        type="file"
                        className="custom-file-input"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept=".jpg, .jpeg, .png"
                      />
                      <img src={PlaceholderImage}
                        onError={(e) => { e.target.src = "https://fudeasy.oss-me-east-1.aliyuncs.com/FE/catalogueThumbnail/noImage.png" }}
                        className={`${loading ? "placeholder" : ""}img-fluid w-100 h-100`}
                        alt="thumbnail"
                        srcSet={imagePath}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
          <div>
            <h3 className="fs-16 text-dark mb-1 fw-semibold">Brand Logo</h3>
            <p className="fs-14 text-dark">Set the logo image. Only <br /> *.png, *.jpg, and *.jpeg image file are accepted.</p>
          </div>
        </div>

        <div className="divider divider-start">
          <span>Header Content</span>
        </div>

        <Row className="mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Logo"
              onChange={(e) => {
                setFormData({ ...formData, isLogo: e.target.checked });
              }}
              checked={formData.isLogo}
              name="isLogo"
            />
          </Col>
          <Col></Col>
        </Row>

        <Row className="align-items-center mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Order Type"
              onChange={(e) => {
                setFormData({ ...formData, isOrderType: e.target.checked });
              }}
              checked={formData.isOrderType}
              name="isOrderType"
            />
          </Col>

          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label
                    className="font-style-bttns"
                    onChange={() =>
                      props.handleBoldToggle("isOrderType", "Bold")
                    }
                  >
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          isOrderType: e.target.checked,
                        });
                      }}
                      value={formData.isOrderType}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label
                    className="font-style-bttns"
                    onChange={() =>
                      props.handleBoldToggle("isOrderType", "italic")
                    }
                  >
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label
                    className="font-style-bttns"
                    onChange={() =>
                      props.handleBoldToggle("isOrderType", "underline")
                    }
                  >
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col xl={4} xs={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="KOT ID"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isKOTId"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Kitchen Name"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isKitchenName"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* SUB HEADER CONTENT */}
      <div>
        <div className="divider divider-start text-dark"><span>Sub Header Content</span></div>
        <Row className="align-items-center mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Section / Area Name"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isSection"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Table Number"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isTableNumber"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Server Name"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isServedByName"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* KOT ITEM DETAILS */}
      <div>
        <div className="divider divider-start text-dark g-3"><span>KOT Item Detail</span></div>
        <Row className="align-items-center mb-3 text-dark">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Item Name"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isItemName"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Item Customization (CM)"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isItemCustomization"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Item Remark"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isItemRemark"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* CANCELLED ITEMS */}
      <div>
        <div className="divider divider-start text-dark"><span>Cancelled Items</span></div>
        <Row className="align-items-center mb-3 text-dark g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14"
              type="switch"
              label="TItle Text"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isTitleText"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Item Name"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isCancelledItems"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="strike"
                    />
                    <span>
                      <i className="ri-strikethrough-2"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* FOOTER CONTENT */}
      <div>
        <div className="divider divider-start text-dark"><span>Footer Content</span></div>
        <Row className="align-items-center mb-3 text-dark g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14"
              type="switch"
              label="Printer Date Time"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isPrintedOn"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="strike"
                    />
                    <span>
                      <i className="ri-strikethrough-2"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3 g-3">
          <Col md={4} sm={12}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Printer Date Time"
              onChange={(e) => {
                setFormData({ ...formData });
              }}
              value={formData}
              name="isReprintedIndicator"
            />
          </Col>
          <Col md={8} sm={12}>
            <Row className="justify-content-end align-items-center">
              <Col xl={7} xs={8}>
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="strike"
                    />
                    <span>
                      <i className="ri-strikethrough-2"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="bold"
                    />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="italic"
                    />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFormData({ ...formData });
                      }}
                      value={formData}
                      name="underline"
                    />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col xl={5} xs={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFormData({ ...formData });
                    }}
                    value={formData}
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

// packages used
import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Badge,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
//css file
import "./menu.scss";
//components used
import AddNewMenu from "./add-menu";
import EditMainMenu from "./edit-menu";
import ManageMainMenu from "./manage-menu";
import Header from "../../../layouts/Header";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
// logical components
import {
  getLoading,
  getMenuList,
} from "../../../application/selectors/indexSelector";
import { loadMenu } from "../../../application/actions/loaderAction";
import {
  GetMenuList,
  MenuDeleteById,
  MenuDetailsSuccess,
  UpdateMain,
  UpdateMainSequence,
} from "../../../application/actions/menuAction";
import Environment from "../../../infrastructure/core/Environment";
import { debounce } from "lodash";

export default function MenuManagement() {
  //theme modes css start
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  useEffect(() => {
    Prism.highlightAll();
    // loading and storing main category
    dispatch(loadMenu);
  }, []);
  // theme modes css end
  const dispatch = useDispatch();
  const { loading, value } = useSelector(getLoading);
  const menuList = useSelector(getMenuList);
  // const [mainId, setMainId] = useState("");
  const [menuData, setMenuData] = useState([]);
  const [addMenuMd, setAddMenuMd] = useState(false);
  const [editMenuMd, setEditMenuMd] = useState(false);
  const [showManageMenu, setShowManageMenu] = useState(false);
  const [mainCatData, setMainCatData] = useState(false);
  useEffect(() => {
    setMenuData(menuList);
  }, [menuList]);
  // add, manage and edit menu modal handling start
  const handleManageMenu = (item) => {
    dispatch(MenuDetailsSuccess(item));
    // dispatch(MenuDetailsById(item))
    setShowManageMenu(true);
  };
  const handleAddMenu = (state) => {
    setAddMenuMd(state);
  };
  const handleEditMenu = (item, state) => {
    setMainCatData(item);
    setEditMenuMd(state);
  };
  // add, manage and edit modal handling end

  // Delete main Category action dispatch start
  const handleDelete = (row, e) => {
    dispatch(MenuDeleteById(row.id));
  };
  // Delete main Category action dispatch end
  const [branchData, setBranchData] = useState(Environment.defaultValue);
  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);


  
  const handleDragStart = (e, id, index) => {
    e.dataTransfer.setData("text/plain", id.toString(), index);
    setDraggedIndex(index);
  };

  const [draggedIndex, setDraggedIndex] = useState(null);
  const [rows, setRows] = useState(menuData);

  const handleDragOver = (e, index) => {
    e.preventDefault();
    const dragIndex = e.dataTransfer.getData('index');
    if (index !== dragIndex) {
      const newRows = [...rows];
      const draggedRow = newRows[dragIndex];
      newRows.splice(dragIndex, 1);
      newRows.splice(index, 0, draggedRow);
      setRows(newRows);
    }
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  const handleDrop = (e, dropId) => {
    const dragId = parseInt(e.dataTransfer.getData("text/plain"), 10);
    if (dragId !== dropId) {
      const newData = [...menuData];
      const dragIndex = newData.findIndex((item) => item.sequence === dragId);
      const dropIndex = newData.findIndex((item) => item.sequence === dropId);
      const [removed] = newData.splice(dragIndex, 1);
      newData.splice(dropIndex, 0, removed);
      setMenuData(newData);
      dispatch(UpdateMainSequence({ initialSequence: dragId, latestSequence: dropId}));
    }
  };



  return (
    <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader} />
      <div className="main main-app p-3 p-lg-4">
        <AddNewMenu
          show={addMenuMd}
          closeFunction={handleAddMenu}
          handleManage={(e) => setShowManageMenu(true)}
        />
        <EditMainMenu
          show={editMenuMd}
          data={mainCatData}
          closeFunction={handleEditMenu}
          handleManage={(e) => setShowManageMenu(true)}
        />
        <ManageMainMenu
          show={showManageMenu}
          closeFunction={(e) => setShowManageMenu(false)}
          handleManage={(e) => setShowManageMenu(true)}
        />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol>
            <h4 className="main-title mb-0">Menu Management</h4>
          </div>
          {/* add menu button */}
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => handleAddMenu(true)}
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline">Add Main Menu</span>
            </Button>
          </div>
        </div>
        {/* menu list table start */}
        <Card className="card-one">
          <Card.Body className="p-3 p-xl-4">
            <div className="d-md-flex align-items-center justify-content-between mb-2">
              <div>
                <h4 className="main-title fs-14 mb-0">List of Main Menu</h4>
              </div>
              <div>
                <div className="form-search me-auto">
                  <i className="ri-search-line"></i>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for item"
                  />
                </div>
              </div>
            </div>
            {menuList && menuList !== undefined && (
              <Table className="w-100">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Category Name</th>
                    <th>Sequence</th>
                    <th>Sub Menu</th>
                    <th>Visibility?</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {menuData?.map((item, index) => (
                    <tr
                      draggable
                      key={index}
                      onDragOver={(e)=> handleDragOver(e, index)}
                      onDrop={(e) => handleDrop(e, item.sequence)}
                      onDragStart={(e) => handleDragStart(e, item.sequence, index)}
                      onDragEnd={handleDragEnd}
                      className={index === draggedIndex ? 'dragging' : ''}
                    >
                      <td
                        style={{
                          transition: "transform 0.3s ease-in-out",
                          cursor: "move",
                        }}
                      >
                        {/* <FaGripVertical /> */}
                      </td>
                      <td>
                        <span>
                          <Link className="dragable-option text-dark">
                            <i className="ri-drag-move-2-line"></i>
                          </Link>
                        </span>
                      </td>
                      <td>
                        <div
                          className="d-flex gap-2"
                          style={{ whiteSpace: "normal" }}
                        >
                          <img
                            src={`https://fudeasy.oss-me-east-1.aliyuncs.com/FE/main-Category-Images/${
                              item?.id
                            }/${
                              item?.id
                            }.png?timestamp=${new Date().getTime()}}`}
                            onError={(e) => {
                              e.target.src =
                                "https://fudeasy.oss-me-east-1.aliyuncs.com/FE/catalogueThumbnail/noImage.png";
                            }}
                            className="img-fluid"
                            alt="thumbnail"
                            style={{ width: "50px", height: "40px" }}
                          />
                          <div>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  {item.mainCatName}
                                </Tooltip>
                              }
                            >
                              <h6 className="mb-0 fw-semibold">
                                <Link onClick={() => handleManageMenu(item)}>
                                  {item.mainCatName}
                                </Link>
                              </h6>
                            </OverlayTrigger>
                            {/* <span className="fs-xs text-secondary">{item?.mainCatDesc}</span> */}
                            {item?.mainCatDesc &&
                            item?.mainCatDesc.length > 50 ? (
                              <span className="fs-xs text-secondary two-line-ellipsis,">
                                {item.mainCatDesc.substring(0, 50)}...
                              </span>
                            ) : (
                              <span className="fs-xs text-secondary">
                                {item?.mainCatDesc}
                              </span>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>{item?.sequence}</td>
                      <td>{item?.subMenuCount}</td>
                      <td>
                        <Badge
                          bg={`${
                            item?.status ===
                            "a5ca48b8-bfd1-42d6-8db1-7021c131e400"
                              ? "success"
                              : "danger"
                          }`}
                          className="fs-14 ht-34 lh-2 wt-100"
                          pill
                        >
                          {item.status ===
                          "a5ca48b8-bfd1-42d6-8db1-7021c131e400"
                            ? "Active"
                            : "InActive"}
                        </Badge>
                      </td>
                      <td className="w-0">
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="secondary"
                            type="button"
                            className="btn-icon me-2"
                            onClick={() => handleManageMenu(item)}
                          >
                            <i className="ri-eye-line"></i>
                          </Button>
                          <Button
                            variant="primary"
                            className="btn-icon me-2"
                            onClick={() => handleEditMenu(item, true)}
                          >
                            <i className="ri-pencil-line"></i>
                          </Button>
                          <Button
                            variant="outline-danger"
                            className="btn-icon me-2"
                            onClick={() => handleDelete(item, true)}
                          >
                            <i className="ri-delete-bin-line"></i>
                          </Button>

                          <Dropdown>
                            <Dropdown.Toggle
                              variant="outline-dark"
                              className="btn-icon d-block p-0"
                             >
                              <i className="ri-more-2-fill fs-18"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                {" "}
                                <i className="ri-global-line align-middle"></i>{" "}
                                <span className="align-middle">
                                  Translation
                                </span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {/* </>
            )} */}
            {!loading && menuList === undefined && (
               <TableShimmerLoader colNames={menuData?.map((column) => column.name)} colsCount={1} />
            )}
            {loading && value === "menuList" && menuList === undefined && (
               <TableShimmerLoader colNames={menuData?.map((column) => column.name)} colsCount={1} />
            )}
          </Card.Body>
        </Card>
        {/* menu list table end */}
      </div>
    </React.Fragment>
  );
}

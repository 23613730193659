import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Table, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteCategory,
  GetCategoryByMainCatId,
  UpdateCategory,
} from "../../../application/actions/menuAction";
import {
  getCategoryList,
  getLoading,
  getMenuDetails,
  getMenuList,
} from "../../../application/selectors/indexSelector";
import EditMainMenu from "./edit-menu";
import AddNewSubMenu from "./add-sub-menu";
import EditSubMenu from "./edit-sub-menu";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../layouts/ShimmerTable";

export default function ManageMainMenu(props) {

  const dispatch = useDispatch();
  const CategoryList = useSelector(getCategoryList);
  // const MenuList = useSelector(getMenuList);
  const MenuDetails = useSelector(getMenuDetails);
  const { loading, value } = useSelector(getLoading);
  useEffect(() => {
    if (MenuDetails?.id !== undefined) {
      dispatch(GetCategoryByMainCatId(MenuDetails?.id));
    }
  }, [MenuDetails]);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const [editMenuMd, setEditMenuMd] = useState(false);
  const [mainCatData, setMainCatData] = useState(false);
  const handleEditMenu = (mainCatData, state) => {
    setMainCatData(mainCatData);
    setEditMenuMd(state);
  };

  const [showSubMenu, setShowSubMenu] = useState(false);
  const [mainCat, setSubMenu] = useState(false);
  const handleAddSubMenu = (mainCat, state) => {
    setSubMenu(mainCat);
    setShowSubMenu(state);
  };

  const [showEditSubMenu, setShowEditSubMenu] = useState(false);
  const [Catdata, setEditSubMenu] = useState(false);
  const handleEditSubMenu = (Catdata, state) => {
    setEditSubMenu(Catdata);
    setShowEditSubMenu(state);
  };
  const handleDelete = (id) => {
    dispatch(DeleteCategory(id));
  };
  const addIndexToData = (data) => {
    return data?.map((row, index) => ({
      ...row,
      index: index + 1, // Adding 1 to start indexing from 1
    }));
  };
  const TableData = addIndexToData(CategoryList);
  const columns = [
    {
      name: "Sub Menu Name",
      selector: (row) => (
        <div className="d-flex gap-2" style={{ whiteSpace: "normal" }}>
          <img src={`https://fudeasy.oss-me-east-1.aliyuncs.com/FE/category-images/${row?.id}/${row?.id}.png?version=${Math.floor(Math.random() * 1000000)}}`}
            onError={(e) => { e.target.src = "https://fudeasy.oss-me-east-1.aliyuncs.com/FE/catalogueThumbnail/noImage.png"; }}
            className="img-fluid" alt="thumbnail" style={{ width: "50px", height: "40px" }} />
          <div>
            <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>{row.name}</Tooltip>} >
              <h6 className="mb-0 fw-semibold">{row?.name}</h6>
            </OverlayTrigger>

            <span className="fs-xs text-secondary">
              {row?.description && row?.description.length > 50 ? (
                <span className="fs-xs text-secondary two-line-ellipsis,">
                  {row.description.substring(0, 50)}...
                </span>
              ) : (
                <span className="fs-xs text-secondary">{row?.description}</span>
              )}
            </span>
          </div>
        </div>
      ),

    },
    {
      name: "Sequence",
      selector: (row) => row?.sequence,

    },
    {
      name: "Translation",
      selector: (row) => <Button
        variant="primary"
        className="d-flex align-items-center gap-2 fs-12"
      >
        <i className="ri-global-line"></i>
        <span className="d-sm-inline"> Translation</span>
      </Button>,

    },

    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleEditSubMenu(row, true)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => handleDelete(row.id, true)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const [submenuData, setSubmenuData] = useState([]);
  const handleDrop = (e, dropId) => {
    const dragId = parseInt(e.dataTransfer.getData("text/plain"), 10);

    if (dragId !== dropId) {
      const newData = [...CategoryList];
      const dragIndex = newData.findIndex((item) => item.sequence === dragId);
      const dropIndex = newData.findIndex((item) => item.sequence === dropId);

      const [removed] = newData.splice(dragIndex, 1);
      newData.splice(dropIndex, 0, removed);

      setSubmenuData(newData);
    }
  };

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", id.toString());
  };


  return (
    <React.Fragment>
      <EditMainMenu
        show={editMenuMd}
        data={mainCatData}
        closeFunction={handleEditMenu}
        handleManage={props?.handleManage}
      />
      <AddNewSubMenu
        show={showSubMenu}
        data={mainCat}
        closeFunction={handleAddSubMenu}
      />
      <EditSubMenu
        show={showEditSubMenu}
        data={Catdata}
        closeFunction={handleEditSubMenu}
      />
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-60"
      >
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title>Manage Main Menu</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl="12">
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <img
                    src={`https://fudeasy.oss-me-east-1.aliyuncs.com/FE/main-Category-Images/${MenuDetails?.id}/${MenuDetails?.id}.png?version=${Math.floor(Math.random() * 1000000)}}`}
                    onError={(e) => {
                      e.target.src =
                        "https://fudeasy.oss-me-east-1.aliyuncs.com/FE/catalogueThumbnail/noImage.png";
                    }}
                    className="img-fluid"
                    alt="thumbnail"
                    width={100}
                  />
                </div>
                <div>
                  <Link to="" className="fs-14 text-dark fw-semibold">
                    {MenuDetails?.mainCatName}
                  </Link>
                  <h6 className="fs-12 mb-0">{MenuDetails?.mainCatDesc && MenuDetails?.mainCatDesc.length > 100 ? (
                    <span className="fs-xs text-secondary">
                      {MenuDetails?.mainCatDesc.substring(0, 100)}...
                    </span>
                  ) : (
                    <span className="fs-xs text-secondary">{MenuDetails?.mainCatDesc}</span>
                  )}</h6>
                  <div className="d-flex align-items-center mt-3">
                    <Button
                      variant="primary"
                      className="d-flex align-items-center me-2 fs-12"
                      onClick={() => handleEditMenu(MenuDetails, true)}
                    >
                      <i className="ri-pencil-line fs-14 me-1"></i> Edit
                    </Button>
                    <Button
                      variant="danger"
                      className="d-flex align-items-center fs-12"
                      onClick={() => handleAddSubMenu(MenuDetails.id, true)}
                    >
                      <i className="ri-add-box-line fs-14 me-1"></i> Add Sub
                      Menu
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <hr />

          <div>
            <div className="mb-2">
              <div>
                <h4 className="main-title fs-14 mb-0">List of Sub Menu</h4>
              </div>
            </div>
            {!loading && (
              <>
                {CategoryList && CategoryList !== undefined && (
                  <DataTable
                    columns={columns}
                    data={TableData}
                    fixedHeader
                    search={true}
                    highlightOnHover
                    pagination
                    className="custom-common-table"
                  ></DataTable>

                  // <Table className="w-100">
                  //   <thead>
                  //     <tr>
                  //       <th></th>
                  //       <th>Sub Menu Name</th>
                  //       <th>Sequence</th>
                  //       <th>Action</th>
                  //     </tr>
                  //   </thead>
                  //   <tbody>

                  //     {TableData?.map((item, index) => (
                  //       <tr 
                  //       key={index}
                  //       draggable
                  //       onDragOver={handleDragOver}
                  //       onDrop={(e) => handleDrop(e, item.sequence)}
                  //       onDragStart={(e) => handleDragStart(e, item.sequence)}>
                  //         <td>
                  //           <Link className="dragable-option text-dark">
                  //             <i className="ri-drag-move-2-line"></i>
                  //           </Link>
                  //         </td>
                  //         <td>
                  //           <div className="d-flex gap-2" style={{ whiteSpace: "normal" }}>
                  //             <img src={`https://fudeasy.oss-me-east-1.aliyuncs.com/FE/category-images/${item?.id}/${item?.id}.png?version=${Math.floor(Math.random() * 1000000)}}`}
                  //               onError={(e) => { e.target.src = "https://fudeasy.oss-me-east-1.aliyuncs.com/FE/catalogueThumbnail/noImage.png"; }}
                  //               className="img-fluid" alt="thumbnail" style={{ width: "50px", height: "40px" }} />
                  //             <div>
                  //               <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>{item.name}</Tooltip>} >
                  //                 <h6 className="mb-0 fw-semibold">{item?.name}</h6>
                  //               </OverlayTrigger>

                  //               <span className="fs-xs text-secondary">
                  //                 {item?.description && item?.description.length > 50 ? (
                  //                   <span className="fs-xs text-secondary two-line-ellipsis,">
                  //                     {item.description.substring(0, 50)}...
                  //                   </span>
                  //                 ) : (
                  //                   <span className="fs-xs text-secondary">{item?.description}</span>
                  //                 )}
                  //               </span>
                  //             </div>
                  //           </div>
                  //         </td>
                  //         <td>{item?.sequence}</td>
                  //         <td className="w-0">
                  //           <div className="d-flex justify-content-end align-items-center gap-2">
                  //             <Button
                  //               variant="primary"
                  //               className="d-flex align-items-center fs-12 text-nowrap"
                  //               onClick={() => handleEditMenu(MenuDetails, true)}
                  //             >
                  //               <i className="ri-pencil-line fs-14 me-1"></i> Edit
                  //             </Button>
                  //             <Button
                  //               variant="danger"
                  //               className="d-flex align-items-center fs-12 text-nowrap"
                  //               onClick={() => handleAddSubMenu(MenuDetails.id, true)}
                  //             >
                  //               <i className="ri-add-box-line fs-14 me-1"></i> Add Sub
                  //               Menu
                  //             </Button>

                  //             <Dropdown>
                  //               <Dropdown.Toggle variant="outline-dark" className="btn-icon d-block p-0">
                  //                 <i className="ri-more-2-fill fs-18"></i>
                  //               </Dropdown.Toggle>
                  //               <Dropdown.Menu>
                  //                 <Dropdown.Item href="#/action-1">
                  //                   {" "}
                  //                   <i className="ri-global-line align-middle"></i>{" "}
                  //                   <span className="align-middle">
                  //                     Translation
                  //                   </span>
                  //                 </Dropdown.Item>
                  //               </Dropdown.Menu>
                  //             </Dropdown>
                  //           </div>
                  //         </td>
                  //       </tr>
                  //     ))}
                  //   </tbody>
                  // </Table>
                )}</>

            )}
            {!loading && CategoryList === undefined &&  <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />}
            {loading && value === 'subMenuList' && CategoryList !== undefined &&  <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />}

          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}

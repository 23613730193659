import React, { useEffect, useState, useRef, useContext } from "react";
import Prism from "prismjs";
import Header from "../../../layouts/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Form,
  Tab,
  Row,
  Col,
  Card,
  Dropdown,
  Nav,
} from "react-bootstrap";
import "./details-view.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  CatalogueItemByIdError,
  DeleteItem,
  GetCatalogueItemById,
  GetMappedAddOns,
  ImageGalleryUpload,
  Imgaeupload,
  IsCatalogueCodeExist,
  UpdateCatalogueAction,
} from "../../../application/actions/ItemAction";
import {
  AddOnsItemList,
  getBranchList,
  getCatalogDataById,
  getCatalogFeature,
  getCatalogId,
  getCatalogueCodes,
  getCategoryList,
  getLoading,
  getReceipeListByCatalogueId,
  getVarianceList,
} from "../../../application/selectors/indexSelector";
import { GetCategoryByMainCatId } from "../../../application/actions/menuAction";
import EditDetails from "./edit-details";
import Loader from "../../../layouts/Loader";
import { loadBranch } from "../../../application/actions/loaderAction";
import environment from "../../../infrastructure/core/Environment";
import PerfectScrollbar from "react-perfect-scrollbar";

// COMPONENTS IMPORT
import PriceLocation from "./PriceLocation/price-location";
import KOTComponent from "./KOT/kot";
import TAXComponent from "./TAX/tax";
import RecipieComponent from "./Recipie/recipie";
import TranslationComponent from "./Translation/translation";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import AddNewCatalogue from "./add-catalogue";
import { GetVarianceByItemId } from "../../../application/actions/varianceAction";
import { GetAddOnsGroupList } from "../../../application/actions/addOnsAction";
import CardShimmerLoader from "../../../layouts/ShimmerCard";
import SideShimmerLoader from "../../../layouts/SideShimmerLoader";
import Environment from "../../../infrastructure/core/Environment";
import { debounce } from "lodash";
import AddonsComponent from "./Addons/addons";
import HealthInfoComponent from "./HealthInformation/health-information";
import tickIcon from "../../../assets/img/icons/tick.png";
import cautionIcon from "../../../assets/img/icons/caution.png";
import { ReceipeListByCatalogueId } from "../../../application/actions/receipeAction";
import emptyImg from "../../../assets/img/empty-image/placeholde-image.svg";

export default function ManageItemDetails() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { user } = useContext(AuthContext);
  const CatalogueCodes = useSelector(getCatalogueCodes);
  const [images, setImages] = useState([]);
  const VarianceList = useSelector(getVarianceList);
  const MappedAddOnsList = useSelector(AddOnsItemList);
  const CatalogDataById = useSelector(getCatalogDataById);
  const Category = useSelector(getCategoryList);
  const { loading, value } = useSelector(getLoading);
  const branchList = useSelector(getBranchList);
  const CatalogFeature = useSelector(getCatalogFeature);
  const receipeList = useSelector(getReceipeListByCatalogueId);
  const Catalogue = CatalogDataById?.catalogue;
  const MenuList = CatalogDataById?.mainCategories;
  const selectedMainCat = CatalogDataById?.mainCategories?.find(
    (mainCat) => mainCat.id === CatalogDataById?.catalogue?.mainCatId
  );
  //  console.log('VarianceList',VarianceList);
  const CategoryList = Category ? Category : CatalogDataById?.categories;
  const selectedCatId = CatalogDataById?.categories?.find(
    (Cat) => Cat.id === CatalogDataById?.catalogue?.catId
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pageNum } = useParams();

  const itemStatusCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "CATSTS"
      )
    : [];
  const itemVisiCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "CATVISI"
      )
    : [];

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);
  const [showAddCatalogue, setShowAddCatalogue] = useState(false);
  useEffect(() => {
    Prism.highlightAll();
    dispatch(CatalogueItemByIdError);
    dispatch(GetCatalogueItemById(id));
    dispatch(GetVarianceByItemId(id));
    dispatch(loadBranch(user.tenanatId));
    dispatch(IsCatalogueCodeExist);
    dispatch(GetAddOnsGroupList);
    dispatch(GetMappedAddOns(id));
    dispatch(ReceipeListByCatalogueId(id));
  }, [id, showAddCatalogue]);
  const handleAddCatalogue = (state) => {
    setShowAddCatalogue(state);
  };
  const handleCatalogueDelete = (id) => {
    dispatch(DeleteItem(id));
    navigate("/item-catalogue");
  };
  const [formData, setFormData] = useState({
    catId: selectedCatId?.id,
    mainCatId: selectedMainCat?.id,
    visibility: CatalogDataById?.catalogue?.visibility,
    status: CatalogDataById?.catalogue?.status,
  });
  useEffect(() => {
    if (CatalogDataById?.catalogue !== undefined) {
      setFormData({
        ...formData,
        catId: CatalogDataById?.catalogue?.catId,
        mainCatId: CatalogDataById?.catalogue?.mainCatId,
        visibility: CatalogDataById?.catalogue?.visibility,
        status: CatalogDataById?.catalogue?.status,
      });
    }
  }, [CatalogDataById?.catalogue]);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const fileInputRef = useRef(null);
  const [imagePath, setImagePath] = useState(null);
  const handleThumbnail = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imagePath = e.target.result;
        setImagePath(imagePath);
        const id = CatalogDataById?.catalogue?.id;
        const data = {
          image: file,
          id: id,
          foldername: "catalogueThumbnail",
        };
        dispatch(Imgaeupload(data));
      };

      reader.readAsDataURL(file);
    }
  };
  const handleImgGallery = (event) => {
    const files = event.target.files;

    // Create an array to store the new images
    const newImages = [];

    // Loop through each selected file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // Add the loaded image to the array of new images
          newImages.push(e.target.result);
          // Check if all images have been loaded
          if (newImages.length === files.length) {
            // After all images have been loaded, update the state with all new images
            setImages([...images, ...newImages]);

            // Dispatch ImageGalleryUpload action once all images are loaded
            const id = CatalogDataById?.catalogue?.id;
            const data = {
              images: files,
              id: id,
              foldername: "catalogueGallery",
            };
            dispatch(ImageGalleryUpload(data));
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleRemoveImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const [showEditDetails, setShowEditDetails] = useState(false);
  const [setItemDetails, setShowItemDetails] = useState(false);
  const handleEditDetails = (catalogue, state) => {
    setShowItemDetails(CatalogDataById);
    setShowEditDetails(state);
  };
  const handleMainCat = (id) => {
    dispatch(GetCategoryByMainCatId(id));
    setFormData({ ...formData, catId: `${environment.defaultValue}` });
    setFormData({ ...formData, mainCatId: id });
  };
  const handleCatId = (e) => {
    setFormData({ ...formData, catId: e.target.value });
    handleCatalogueAction(
      formData.status,
      e.target.value,
      formData.mainCatId,
      e.target.value
    );
  };
  const handleStatus = (e) => {
    setFormData({ ...formData, status: e.target.value });
    handleCatalogueAction(
      e.target.value,
      formData.visibility,
      formData.mainCatId,
      formData.catId
    );
  };
  const handleVisibility = (e) => {
    setFormData({ ...formData, visibility: e.target.value });
    handleCatalogueAction(
      formData.status,
      e.target.value,
      formData.mainCatId,
      formData.catId
    );
  };
  const handleCatalogueAction = (status, visibility, mainCatId, catId) => {
    const Data = {
      catalogueId: id,
      status: status,
      visibility: visibility,
      mainCategoryId: mainCatId,
      categoryId: catId,
    };
    dispatch(UpdateCatalogueAction(Data));
  };
  const FoodType = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "FOODTY"
  );
  const CatalogId = useSelector(getCatalogId);

  useEffect(() => {
    if (CatalogId !== undefined) {
      navigate(`/item-catalogue/details/${CatalogId}`);
    }
  }, [CatalogId]);
  const [branchData, setBranchData] = useState(Environment.defaultValue);
  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);

  return (
    <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader} />
      <AddNewCatalogue
        show={showAddCatalogue}
        CatalogueCodes={CatalogueCodes}
        data={CatalogDataById}
        closeFunction={handleAddCatalogue}
      />
      <div className="main main-app manage-item-page p-3 p-lg-4">
        <EditDetails
          show={showEditDetails}
          data={setItemDetails}
          Catalogue={Catalogue}
          CatalogueCodes={CatalogueCodes}
          closeFunction={handleEditDetails}
        />
        <Row className="mb-4 align-items-center">
          <Col>
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/item-catalogue">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
              <h4 className="main-title mb-0">Item Details</h4>
            </div>
          </Col>
          <Col>
            <div className="d-flex gap-2 justify-content-end mt-md-0 align-items-center">
              <Button
                variant="primary"
                className="fs-14 d-flex align-items-center text-nowrap"
                onClick={() => handleEditDetails(Catalogue, true)}
              >
                <i className="ri-pencil-line fs-18 lh-1 me-1"></i>
                <span className="align-middle">Edit Detail</span>
              </Button>

              <Button
                variant="danger"
                className="btn-icon wt-35 ht-35"
                onClick={() => {
                  handleCatalogueDelete(id);
                }}
              >
                <i className="ri-delete-bin-line"></i>
              </Button>
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-dark"
                  className="btn-icon custom-dropdown-action wt-35 ht-35"
                >
                  <i className="ri-more-2-fill fs-18"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    onClick={() => handleAddCatalogue(true)}
                  >
                    <i className="ri-file-copy-line"></i> Duplicate
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <Row className="g-3">
          <Col xs={12} md={12} xl={9}>
            <Row className="g-3 mb-3">
              <Col xl={3} md={4} sm={12}>
                {/* THUMBNAIL CARD */}
                <Card className="card-one mb-2">
                  <Card.Body>
                    <h3 className="fs-14 mb-0 text-dark mb-4">Thumbnail</h3>
                    <div className="item-manage-thumbnail">
                      <div className="text-center bg-white thumbnail mb-3">
                        <Form.Group controlId="formFile">
                          <div className="custom-update-image">
                            <Link className="edit-image bg-dark text-white">
                              <i className="ri-pencil-line fs-16"></i>
                            </Link>
                            <div
                              className={`${
                                loading && CatalogDataById === undefined
                                  ? "placeholder"
                                  : ""
                              } uploaded-image`}
                            >
                              <Form.Control
                                type="file"
                                className="custom-file-input"
                                ref={fileInputRef}
                                onChange={handleThumbnail}
                                accept=".jpg, .jpeg, .png"
                              />
                              <img
                                src={`https://fudeasy.oss-me-east-1.aliyuncs.com/FE/catalogueThumbnail/${CatalogDataById?.catalogue?.id}/${CatalogDataById?.catalogue?.id}.png`}
                                onError={(e) => {e.target.src = emptyImg;}}
                                className={`${ loading && CatalogDataById === undefined ? "placeholder" : ""} img-fluid ht-100 wt-100`}
                                alt="thumbnail"
                                srcSet={imagePath}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <p className="fs-10 text-secondary text-center">
                        Set the item thumbnail image, only *.png *.jpg *.jpeg
                        image file are accepted
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={9} md={8} sm={12}>
                {/* ITEMS DETAILS */}
                <Card className="card-one mb-2">
                  <Card.Body>
                    {((loading && value === "itemDetails") ||
                      CatalogDataById === undefined) && (
                      <CardShimmerLoader columnCount={4} rowCount={4} />
                    )}
                    {CatalogDataById !== undefined && (
                      <div className="item-details-name">
                        <div className="flex-100">
                          <h2 className={`fs-16 text-dark d-flex align-items-center${loading && CatalogDataById === undefined ? "placeholder" : ""}`}>
                            <span
                              className={`indicator d-block me-2 ${
                                Catalogue?.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg": 
                                Catalogue?.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg": 
                                Catalogue?.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" : "not-applicable"
                              }`}
                            ></span>
                            <span className={`${loading && CatalogDataById === undefined ? "placeholder" : ""}`}>
                              {Catalogue?.catalogueName}
                            </span>
                          </h2>
                          {/* className={`fs-11 mt-1 ${Catalogue?.description} ? 'placeholder' : ''}`} */}
                          <h5
                            className={`fs-11 mt-1${loading && CatalogDataById === undefined ? "placeholder" : ""}`}>
                            <span>
                              {Catalogue?.description &&
                              Catalogue?.description.length > 50 ? (
                                <span className="fs-xs text-secondary">{Catalogue.description.substring(0, 50)}...</span>
                              ) : (
                                <span className="fs-xs text-secondary">{Catalogue?.description}</span>
                              )}
                            </span>
                          </h5>
                          {/* <h5 className={`fs-11 mt-1`}>{Catalogue?.description}</h5> */}
                          <h3 className="fs-14 mt-1 text-dark d-flex ">
                            <span className={`${loading && CatalogDataById === undefined ? "placeholder" : ""}`}>
                              {selectedMainCat?.mainCatName}
                            </span>
                            <i className="ri-arrow-right-s-line fs-16 align-middle"></i>
                            <span className={`${loading && CatalogDataById === undefined ? "placeholder" : ""}`}>
                              {Catalogue?.catalogueName}
                            </span>
                          </h3>
                        </div>

                        <Row className="g-3 align-items-center flex-100 mt-3">
                          <Col xl={2} md={3} xs={6}>
                            <h5 className="fs-14 text-dark">Short Code</h5>
                            <h3 className="fs-14 text-dark fw-bold">
                              <span
                                className={`${
                                  loading && CatalogDataById === undefined
                                    ? "placeholder"
                                    : ""
                                }`}
                              >
                                {Catalogue?.catalogueCode}
                              </span>
                            </h3>
                          </Col>
                          <Col xl={2} md={3} xs={6}>
                            <h5 className="fs-14 text-dark">Food Type</h5>
                            <h3 className="fs-14 text-dark fw-bold">
                              <span
                                className={`${
                                  loading && CatalogDataById === undefined
                                    ? "placeholder"
                                    : ""
                                }`}
                              >
                                {FoodType.find(
                                  (foodType) =>
                                    foodType.id === Catalogue?.foodType
                                )?.fieldValue || ""}
                              </span>
                            </h3>
                          </Col>
                          <Col xl={2} md={3} xs={6}>
                            <h5 className="fs-14 text-dark">KOT Print</h5>
                            <h3 className="fs-14 text-dark fw-bold">
                              {CatalogDataById?.catalogueFeatures?.isKOT
                                ? "Yes"
                                : "No"}
                            </h3>
                          </Col>
                          <Col xl={2} md={3} xs={6}>
                            <h5 className="fs-14 text-dark">Is Day Special</h5>
                            <h3 className="fs-14 text-dark fw-bold">
                              {CatalogDataById?.catalogue?.isDaySpecial
                                ? "Yes"
                                : "No"}{" "}
                              <span className="fs-12 fw-normal">
                                {CatalogDataById?.catalogue?.isDaySpecial ? (
                                  CatalogDataById?.catalogueOnDays?.length ===
                                  7 ? (
                                    "(All Days)"
                                  ) : (
                                    <>
                                      (
                                      {CatalogDataById?.catalogueOnDays?.map(
                                        (days, index) =>
                                          index !==
                                          CatalogDataById.catalogueOnDays
                                            .length -
                                            1
                                            ? `${days.dayName}, `
                                            : days.dayName
                                      )}
                                      )
                                    </>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </h3>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* ITEM DETAILS TABS */}
            {((loading && value === "itemDetails") ||
              CatalogDataById === undefined) && (
              <CardShimmerLoader columnCount={4} rowCount={4} />
            )}

            {CatalogDataById !== undefined && (
              <Card className="card-one h-100 item-details-slider-tab">
                <Card.Body className="custom-file-sidebar">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="price_location_tab"
                  >
                    <PerfectScrollbar className="file-sidebar item-master-tabs page-in-tabs">
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link
                            className="position-relative"
                            eventKey="price_location_tab"
                          >
                            Price & Location{" "}
                            <span className="position-absolute right-10 top-10">
                              <img
                                className="wt-15"
                                src={
                                  VarianceList?.globalVariances.length === 0
                                    ? cautionIcon
                                    : tickIcon
                                }
                                alt="tick"
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="position-relative"
                            eventKey="addons_tab"
                          >
                            Addons{" "}
                            <span className="position-absolute right-10 top-10">
                              <img
                                className="wt-15"
                                src={
                                  MappedAddOnsList.length !== 0
                                    ? cautionIcon
                                    : tickIcon
                                }
                                alt="tick"
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="position-relative"
                            eventKey="kot_tab"
                          >
                            Kitchen Group{" "}
                            <span className="position-absolute right-10 top-10">
                              <img
                                className="wt-15"
                                src={cautionIcon}
                                alt="tick"
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="position-relative"
                            eventKey="tax_tab"
                          >
                            Tax{" "}
                            <span className="position-absolute right-10 top-10">
                              <img
                                className="wt-18"
                                src={
                                  CatalogFeature?.isTaxOverride
                                    ? cautionIcon
                                    : tickIcon
                                }
                                alt="caution"
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="position-relative"
                            eventKey="recipie_tab"
                          >
                            Recipie{" "}
                            <span className="position-absolute right-10 top-10">
                              <img
                                className="wt-15"
                                src={
                                  receipeList?.length !== 0
                                    ? cautionIcon
                                    : tickIcon
                                }
                                alt="tick"
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="position-relative"
                            eventKey="translation_tab"
                          >
                            Translation{" "}
                            <span className="position-absolute right-10 top-10">
                              <img
                                className="wt-15"
                                src={cautionIcon}
                                alt="tick"
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="position-relative"
                            eventKey="Health_information_tab"
                          >
                            Health Information{" "}
                            <span className="position-absolute right-10 top-10">
                              <img
                                className="wt-15"
                                src={cautionIcon}
                                alt="tick"
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                      <Tab.Content>
                        <Tab.Pane eventKey="price_location_tab">
                          <PriceLocation
                            IsGlobal={Catalogue?.isGlobalVariance}
                            BranchList={branchList}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="addons_tab">
                          <AddonsComponent />
                        </Tab.Pane>
                        <Tab.Pane eventKey="kot_tab">
                          <KOTComponent />
                        </Tab.Pane>
                        <Tab.Pane eventKey="tax_tab">
                          <TAXComponent />
                        </Tab.Pane>
                        <Tab.Pane eventKey="recipie_tab">
                          <RecipieComponent />
                        </Tab.Pane>
                        <Tab.Pane eventKey="translation_tab">
                          <RecipieComponent />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Health_information_tab">
                          <HealthInfoComponent />
                        </Tab.Pane>
                      </Tab.Content>
                    </PerfectScrollbar>
                  </Tab.Container>
                </Card.Body>
              </Card>
            )}
          </Col>

          <Col xs={12} md={12} xl={3}>
            <Card className="card-one mb-2 ht-auto">
              <Card.Body>
                {((loading && value === "itemDetails") ||
                  CatalogDataById === undefined) && <SideShimmerLoader />}
                {CatalogDataById !== undefined && (
                  <div className="placeholder-glow">
                    <div className="mb-3">
                      <Form.Label className="position-relative w-100">
                        Status: <sup className="text-danger">*</sup>{" "}
                        <span
                          className={
                            formData.status ===
                            "9251e44c-dda2-40d9-8d96-b392d974331b"
                              ? "order-indicator"
                              : "order-pending"
                          }
                        ></span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={formData.status}
                        onChange={handleStatus}
                      >
                        <option value={`${environment.defaultValue}`} disabled>
                          Choose Item Status
                        </option>
                        {itemStatusCommonList.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.fieldValue}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="mb-3">
                      <Form.Label>
                        Visibility: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={formData.visibility}
                        onChange={handleVisibility}
                      >
                        <option value={`${environment.defaultValue}`} disabled>
                          Choose Item Visibility
                        </option>
                        {itemVisiCommonList.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.fieldValue}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="mb-3">
                      <Form.Label>
                        Main Menu: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="mainCatId"
                        value={formData.mainCatId}
                        onChange={(e) => handleMainCat(e.target.value)}
                      >
                        <option value={`${environment.defaultValue}`} disabled>
                          Select Main Menu
                        </option>
                        {MenuList?.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.mainCatName}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="mb-3">
                      <Form.Label>
                        Sub Menu: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="catId"
                        value={formData.catId}
                        onChange={(e) => handleCatId(e)}
                      >
                        <option value={`${environment.defaultValue}`}>
                          Choose Sub Menu
                        </option>
                        {CategoryList?.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                )}

              </Card.Body>
            </Card>
            <Card className="card-one ht-auto">
              <Card.Body>
                <h3 className="fs-14 mb-0 text-dark mb-4">Image Gallery</h3>

                <div className="image-galler-view">
                  <Form.Group controlId="formFile">
                    <div className="custom-update-image">
                      <Form.Control
                        type="file"
                        className="custom-file-input"
                        ref={fileInputRef}
                        onChange={handleImgGallery}
                        accept=".jpg, .jpeg, .png"
                        multiple
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFileInput"
                      >
                        <i className="ri-add-fill"></i>
                      </label>
                    </div>
                  </Form.Group>

                  <div className="custom-update-image gallery">
                    <img
                      src="https://placehold.co/90x70@2x.png"
                      alt="gallery"
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {CatalogDataById?.catalogue?.length === 0 &&
        loading &&
        CatalogDataById === undefined && <Loader message="Loading Item Data" />}
    </React.Fragment>
  );
}
